import React from 'react';

class PokemonPlayerCard extends React.Component {
	
	
	render () {
		return (
				<div>
					<div class="box"> 
						<h2>{this.props.name}</h2>
						{this.props.children}
					</div>
				</div>
				);
	};
	
}
export default PokemonPlayerCard;