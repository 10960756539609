import React from 'react';
import PokemonPlayerCard from './PokemonPlayerCardComponent.js';

import { Image, Item } from 'semantic-ui-react'

class HoundoomComponent extends React.Component {
	render() {
		return (
			<div>
			<div class="row">
				<div class="column side" >
					<div class="mega-houndoom" />
				</div>
				<div class="column middle" >
					<PokemonPlayerCard name="Mega Houndoom">
					Type: Dark-Fire
					Height: 1.9m
					Weight: 49.5 kg
					</PokemonPlayerCard>
				</div>
				
				
				<div class="column side" >
				</div>
			</div>
			<div class="row">
				<div class="column side" >
				</div>
				<div class="column middle" >

				</div>
				
				
				<div class="column side" >
				</div>
			</div>
			</div>
		)
	}
	
}
export default HoundoomComponent;