import React from 'react';
import HoundoomComponent from './PokemonComponents/HoundoomComponent.js';
import '../css/pokemonComponent.css';

class PokemonComponent extends React.Component {
	
    render() {
      return (
	  
		<div>
		
			<HoundoomComponent />
		</div>

	  )
	}
};

export default PokemonComponent;