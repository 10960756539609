// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:e2209f76-1065-48d4-91b2-3dd6fb38bfb1",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_kDtGW4Whm",
    "aws_user_pools_web_client_id": "2km61tnm7ef053jt5a0l0rgvrr",
    "oauth": {}
};


export default awsmobile;
