import React from 'react';
import './css/App.css';
import './css/fonts.css';
import '@aws-amplify/ui/dist/style.css';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import Container from './components/Container.js';
Amplify.configure(awsconfig);


// eslint-disable-next-line
/*			<AmplifySignUp headerText="Welcome to Timmy's website" formFields={[
			  {type: 'username' },
			  {type: 'email' },
			  {type: 'password' },
			]} slot="sign-up"></AmplifySignUp> */


function App() {
  return (
<div>
	<Container />
</div>
  );
}

export default App;
