import React from 'react';
//import FeedbackForm from './FeedbackFormComponent.js';
import '../css/homeComponent.css';

class HomeComponent extends React.Component {
	
    render() {
      return (
	  
		<div>
			<div class="row">
				<div class="column side" >
					<div class="avatar-timmy" />
				</div>
				<div class="column middle" >
					<div class="box sbleft"> Hello! 
					My name is Timmy, and this site was created just for me and my family. I'm still working on it, and there's a lot more to add, but I hope you like it so far!

					</div>
				</div>
				<div class="column side" >            </div>
			</div>
			<div class="row rowdark">
				<div class="column side" >			</div>
				<div class="column middle" >
					<div class="box sbright sbblue box-blue"> Hi there! 
					I'm Timmy's dad, and I'm the designer, developer and architect for this site. Over time, I'm hoping to add a lot more to this site, so any suggestions are welcome.

					</div>
				</div>
				<div class="column side" >
					<div class="avatar-dad" />
				</div>
			</div>
			<div class="row">
				<div class="column side" >
					<div class="avatar-puppy" />
				</div>
				<div class="column middle" >
					<div class="box sbleft"> Woof! Woof! 
					This is puppy, he's the best. 
					<br/>
					<br/>
					</div>
				</div>
				<div class="column side" >
				</div>
			</div>
			<div class="row rowdark">
				<div class="column side" >			</div>
				<div class="column middle" >
					<div class="box sbright sbpurple box-purple"> Hi! 
					I'm Timmy's Mum. I'm still waiting for Timmy's dad to build a website for me! Hopefully soon you'll be able to see my craft at <a href="https://www.designedbyanneliese.com">DesignedByAnneliese.com</a>, but I think it might be awhile, oh well...

					</div>
				</div>
				<div class="column side" >
					<div class="avatar-mummy" />
				</div>
			</div>

			<div class="row">
				<div class="column side" >
					<div class="avatar-daniel" />
				</div>
				<div class="column middle" >
					<div class="box sbleft sbgreen box-green"> Yo! 
					I'm Daniel, Timmy's brother. I'm going to have my own website soon, but my Dad hasn't had time yet to make it.  When it's ready, check it out at <a href="https://www.DanielTow.com">DanielTow.com</a>.

					</div>
				</div>
				<div class="column side" >
				</div>
			</div>
			
		</div>

	  )
	}
};

export default HomeComponent;