import React from 'react';
//import FeedbackForm from './FeedbackFormComponent.js';
import '../css/container.css';
import HomeComponent from './HomeComponent.js';
import PokemonComponent from './PokemonComponent.js';
import { Menu } from 'semantic-ui-react'
import { AmplifySignIn, AmplifyAuthenticator, AmplifyGreetings } from '@aws-amplify/ui-react';

class Container extends React.Component {
	state = { activeTab: 'Home' }
	
	handleMenuClick = (e, { name }) => this.setState({ activeTab: name })
	
    render() {
		const { activeTab } = this.state
      return (
	<div id="container">
		<div class="mainheader"></div>

		<AmplifyAuthenticator>
			<AmplifyGreetings />
      <Menu secondary>
        <Menu.Item
          name='Home'
		  active={activeTab === 'Home'}
            onClick={this.handleMenuClick}
        />
        <Menu.Item
          name='Pokemon'
		  active={activeTab === 'Pokemon'}
            onClick={this.handleMenuClick}
        />
      </Menu>
			
			<AmplifySignIn hideSignUp="true" headerText="Welcome to Timmys website. Please sign in to continue" slot="sign-in"></AmplifySignIn>
			
			{activeTab === 'Home' &&
				<HomeComponent />
			}
			{activeTab === 'Pokemon' &&
				<PokemonComponent />
			}
		</AmplifyAuthenticator>
		<div class="footer">
			This website was created for the personal use of Timmy Tow only. 
		</div>  
	</div>
	  )
	};	  
}  
export default Container;